<template>
  <div>
    <div class="messenger">
      <messenger-wrapper>
        <sc-messenger :is-screen="true" />
      </messenger-wrapper>
    </div>
  </div>
</template>

<script>
import MessengerWrapper from "@/components/common/messenger/MessengerWrapper";
import ScMessenger from "@/components/chat/views/Messages";

export default {
  components: {
    MessengerWrapper,
    ScMessenger,
  },
};
</script>

<style scoped lang="less">
.messenger {
  box-sizing: border-box;
  display: flex;
  flex: 1 1 380px;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding-top: 12px;
  overflow: hidden;
  background-color: #fff;
  transition: all ease 0.3s;
}
</style>
