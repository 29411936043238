<template>
  <div class="messenger-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MessengerWrapper",
};
</script>

<style scoped lang="less">
.messenger-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: 594px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: hidden;
}
</style>
